@font-face {
  font-family: 'Rubik';
  src: url('./fonts/Rubik.woff2') format('woff2'); /* Super Modern Browsers */
}
@font-face {
  font-family: 'NotoSans';
  src: url('./fonts/NotoSerifCJKsc-Medium.otf') format('opentype'); /* Super Modern Browsers */
}
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Rubik, NotoSans, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
