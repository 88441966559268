.social-icon {
    font-size: 15px;
    background: gainsboro;
    width: 65px;
    height:65px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 5px;
    color: black ;

}

.social-icons{
    display:flex;
    justify-content: center;
}
.social-icon:hover{
    background: gray ;
}